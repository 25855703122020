import React from 'react';
import { Helmet } from 'react-helmet'; 
import { Container, Row } from 'react-bootstrap';
import './Aliexpress.css';
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const AliexpressPage = () => {
  return (
    <div>
    <Navbar />
    <Helmet>
        <title>Kamsiparts Automotive | Best Car Parts in Nigeria & Lagos State</title>
        <meta
          name="description"
          content="Kamsiparts Automotive offers genuine car parts in Nigeria and Lagos State. Reliable, safe, and convenient for vehicle owners."
        />
        <meta
          name="keywords"
          content="Car parts in Nigeria, Car parts in Lagos State, Kamsiparts Automotive, original auto parts, TCHIKAMALOR Global Nig. Ltd, Kamsiparts platform"
        />
      </Helmet>
    <Container fluid className="d-flex justify-content-center align-items-center">
      <div className="iframe-container">
        <iframe
          src="https://aliexpress.kamsiparts.com/"
          title="AliExpress Kamsiparts"
          style={{
            border: "none",
            width: "100%",
            height: "100%",
          }}
          allowFullScreen
        ></iframe>
      </div>
    </Container>
    <Footer />
    </div>
  );
};

export default AliexpressPage;
