import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'; // Import useLocation

import { addItemToCart, addToCart, getCart } from '../../actions/cartActions';
import { Alert, Button, Form } from 'react-bootstrap';

let tokenParse = [];

const BuyingBox = ({ productId, stock }) => {
  const { cart, loadingCartItem } = useSelector((state) => state.cartReducer);
  const { token } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  
  const location = useLocation(); // Use the useLocation hook
  const item = location.state?.item; // Access item from location.state

  useEffect(() => {
    if (token !== null && token !== 'undefined') {
      tokenParse = JSON.parse(token);
      dispatch(getCart(tokenParse?.id));
    }
  }, [dispatch, token]);

  const addCart = (cartData, productId) => {
    if (quantity > stock) {
      setErrorMessage('Quantity exceeds available stock');
      return;
    }
    if (!token) {
      setErrorMessage('Please register or log in to add items to your cart.');
      // Perform redirection to the login page
      return;
    }
    dispatch(addToCart(tokenParse?.id, cartData?.quantity));
    dispatch(addItemToCart(cartData?.id, productId, quantity));
    setSuccessMessage(`${quantity} item(s) added to the cart`);
  };

  // Function to generate WhatsApp URL with item details
  const generateWhatsAppURL = () => {
    if (!item) {
      console.warn('Item is undefined in BuyingBox component');
      return '';
    }
    const { title, price, sku } = item;
    const message = `I'd like to buy: *${title || 'Unknown Product'}*\nSKU: ${sku || 'N/A'}\nQuantity: ${quantity}\nPrice: *${price || 'N/A'}*\n`;
    return `https://wa.me/2349098370120?text=${encodeURIComponent(message)}`;
  };

  return (
    <div className="my-4" style={{ boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', padding: '15px' }}>
      <div className="mb-3">
        <div className="mb-2">
          <span>Stock: {stock}</span>
        </div>
        <span>Qty:</span>
        <Form.Control
          type="number"
          name="qty"
          max={stock}
          min={1}
          value={quantity}
          onChange={(e) => setQuantity(Number(e.target.value))}
        />
      </div>
      {loadingCartItem ? (
        <div>Loading...</div>
      ) : (
        <>
          <Button onClick={() => addCart(cart, productId)} variant="primary" className="me-2 mb-2">
            Add to Cart
          </Button>
          <Button 
            as="a"
            href={generateWhatsAppURL()} 
            target="_blank"
            rel="noopener noreferrer"
            variant="primary"
          >
            Buy Now
          </Button>
        </>
      )}
      {errorMessage && <Alert variant="danger" className="mt-3">{errorMessage}</Alert>}
      {successMessage && <Alert variant="success" className="mt-3">{successMessage}</Alert>}
    </div>
  );
};

export default BuyingBox;