import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import "./WhatsAppChatWidget.css"; // Add custom CSS for animations and positioning

const WhatsAppChatWidget = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [name, setName] = useState("");
  const [request, setRequest] = useState("");
  const [vinNumber, setVinNumber] = useState("");
  const [contactMethod, setContactMethod] = useState("");

  const handlePopupToggle = () => setShowPopup((prev) => !prev);

  const handleNextStep = () => setCurrentStep((prevStep) => prevStep + 1);

  const handlePreviousStep = () => setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));

  const handleProceed = () => {
    const chatMessage = `Hello, my name is ${name}. I want to buy: ${request}. Vin Number: ${vinNumber}. Preferred contact method: ${contactMethod}.`;
    const whatsappUrl = `https://wa.me/2349098370120?text=${encodeURIComponent(chatMessage)}`;
    window.open(whatsappUrl, "_blank");
    setShowPopup(false);
    setCurrentStep(1); // Reset steps
  };

  return (
    <div>
      {/* Widget Button */}
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: 1000,
        }}
      >
        <div
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            backgroundColor: "#25D366",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
            cursor: "pointer",
            transition: "transform 0.3s ease-in-out",
          }}
          onClick={handlePopupToggle}
          className="whatsapp-widget-button"
        >
          <FontAwesomeIcon icon={faWhatsapp} style={{ color: "#fff", fontSize: "2rem" }} />
          
        </div>
        
      </div>

      {/* Popup */}
      {showPopup && (
        <div
          style={{
            position: "fixed",
            bottom: "90px",
            right: "20px",
            width: "300px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
            padding: "15px",
            zIndex: 1001,
          }}
          className="fade-in whatsapp-popup"
        >
          {currentStep > 1 && (
            <div className="mb-3">
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ cursor: "pointer", fontSize: "1.5rem" }}
                onClick={handlePreviousStep}
              />
            </div>
          )}

          {currentStep === 1 && (
            <div>
              <label htmlFor="name" className="form-label">
                What is your name?
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your name"
              />
              <Button
                className="mt-3"
                onClick={handleNextStep}
                disabled={!name.trim()}
                variant="success"
              >
                Next
              </Button>
            </div>
          )}

          {currentStep === 2 && (
            <div>
              <label htmlFor="request" className="form-label">
                What do you want to buy? (Include item name, part number, etc.)
              </label>
              <textarea
                className="form-control"
                id="request"
                rows="3"
                value={request}
                onChange={(e) => setRequest(e.target.value)}
                placeholder="Enter your request"
              ></textarea>
              <Button
                className="mt-3"
                onClick={handleNextStep}
                disabled={!request.trim()}
                variant="success"
              >
                Next
              </Button>
            </div>
          )}

          {currentStep === 3 && (
            <div>
              <label htmlFor="vin" className="form-label">
                VIN Number (optional):
              </label>
              <input
                type="text"
                className="form-control"
                id="vin"
                value={vinNumber}
                onChange={(e) => setVinNumber(e.target.value)}
                placeholder="Enter VIN number"
              />
              <Button
                className="mt-3"
                onClick={handleNextStep}
                variant="success"
              >
                Next
              </Button>
            </div>
          )}

          {currentStep === 4 && (
            <div>
              <label htmlFor="contactMethod" className="form-label">
                Preferred Contact Method (Phone, Email, etc.):
              </label>
              <input
                type="text"
                className="form-control"
                id="contactMethod"
                value={contactMethod}
                onChange={(e) => setContactMethod(e.target.value)}
                placeholder="Enter contact method"
              />
              <p className="mt-3">Would you like to proceed to chat with customer service?</p>
              <Button
                className="me-2"
                variant="secondary"
                onClick={handlePopupToggle}
              >
                Cancel
              </Button>
              <Button
                variant="success"
                onClick={handleProceed}
              >
                Proceed to Chat
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WhatsAppChatWidget;
