import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const OximoProductPage = () => {
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === "oximo" && event.data.param === "height") {
        const iframe = document.getElementById("oximo-iframe");
        if (iframe) {
          iframe.style.height = `${event.data.value}px`;
        }
      }
    };

    window.addEventListener("message", handleMessage);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <div>
      <Navbar />
      <Container>
        <h1 className="my-4">Oximo Wipers</h1>
        <iframe
          id="oximo-iframe"
          style={{ width: "100%", border: "none" }}
          src="https://www.oximo.pl/widget/"
          title="Oximo Widget"
        ></iframe>
      </Container>
      <Footer />
    </div>
  );
};

export default OximoProductPage;
